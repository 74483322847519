.button_animation_1 {
  -webkit-transition: background-color 0.5s;
  transition: background-color 0.5s;
}
.button_animation_1 > span {
  display: inline-block;
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button_animation_1::before {
  content: attr(data-text);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button_animation_1:hover::before {
  opacity: 0;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}
.button_animation_1:hover > span {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.button_animation_1:hover > span:nth-child(1) {
  -webkit-transition-delay: 0.06s;
  transition-delay: 0.06s;
}
.button_animation_1:hover > span:nth-child(2) {
  -webkit-transition-delay: 0.13s;
  transition-delay: 0.13s;
}
.button_animation_1:hover > span:nth-child(3) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.button_animation_1:hover > span:nth-child(4) {
  -webkit-transition-delay: 0.27s;
  transition-delay: 0.27s;
}
.button_animation_1:hover > span:nth-child(5) {
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
}

.button_animation_2 {
  -webkit-transition: border-color 0.5s, background-color 0.5s;
  transition: border-color 0.5s, background-color 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button_animation_2::after {
  content: attr(data-text);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-transform: translate3d(0, 25%, 0);
  transform: translate3d(0, 25%, 0);
}

.button_animation_2::after,
.button_animation_2 > span {
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}

.button_animation_2:hover::after {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.button_animation_2:hover > span {
  opacity: 0;
  -webkit-transform: translate3d(0, -25%, 0);
  transform: translate3d(0, -25%, 0);
}
