.sd-container {
  position: relative;
}

.sd {
  padding: 5px 10px;
}

.open-button {
  position: absolute;
  top: 10px;
  right: 15px;
  background: #fff;
  pointer-events: none;
}

.open-button button {
  border: none;
  background: transparent;
}
